import React from 'react';
import { observer, inject } from 'mobx-react';
// import ReactDOM from 'react-dom';
import style from './style.module.css';
import Item from './Item';
import Spinner from 'Components/Spinner';
import ScheduleCare from './ScheduleCare';

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})

@observer
class ItemList extends React.Component {
    constructor(props) {
        super(props)
        this.containerRef = React.createRef()
    }
    

    //////////////yaroslav code-----------------
    // componentDidMount = () => {
    //     window.addEventListener('resize', this.checkLoader);
    // }

    // componentWillUnmount = () => {
    //     window.removeEventListener('resize', this.checkLoader);
    // }
    /////////////////-------------------------------

    componentDidMount() {
        const { setSearchValue, setSubcatItemList } = this.props.myShopApiStore;
        setSubcatItemList(null);
        //setSearchValue('', true);
    }

    componentWillUnmount = () => {
        const { setSubcatItemList } = this.props.myShopApiStore;
        setSubcatItemList(null);
    }

    checkLoader = (e) => {
        if (this.props.myShopApiStore.categoryPageCount > 0 && this.props.myShopApiStore.categoryPageCount > this.props.myShopApiStore.categoryPage)
        {
            const scrollTop = this.containerRef.current.scrollTop;
            const clientHeight = this.containerRef.current.clientHeight;
            if(clientHeight - scrollTop < 100) {
                this.props.myShopApiStore.getItemList(this.props.myShopApiStore.selectedCategoryIdx, false);
            }
        }
    }

    
    render() {
        const { itemList, suggestions, searchValue, categoryPageCount, subcatItemList,
                    categoryPage, isProductsLoading, isSearchingProducts,  getSelCategory } = this.props.myShopApiStore;
        const loading = ((categoryPageCount > 0 && categoryPageCount > categoryPage) || 
                            isProductsLoading) || isSearchingProducts;
       
        const sC = getSelCategory();
        const isSchedule = sC?(sC.fromTime && sC.toTime && sC.fromTime!==sC.toTime ):false;
       
        return (
            <div className={style.Container} ref={this.containerRef} onScroll={this.checkLoader}>
                <div className={style.TopDiv}>
                    {   
                        searchValue
                        ?
                            suggestions.length
                            ?
                                suggestions.map((item, index) => {
                                    this.props.myShopApiStore.setMaxSideMenuIdx(index);
                                    
                                    return (
                                        <Item item={item} index={index} key={`p_${index}`} toggleBasket={this.props.toggleBasket}/>
                                    )
                                })
                            :
                                null
                        :   
                            itemList
                            ? subcatItemList
                                ?
                                    subcatItemList.map((item, index) => {
                                        this.props.myShopApiStore.setMaxSideMenuIdx(index);
                                        return (
                                            <Item item={item} index={index} key={`p_${index}`} toggleBasket={this.props.toggleBasket}/>
                                        )
                                    })
                                : <>
                                   {isSchedule?<ScheduleCare category={sC} />:null}
                                    {itemList.map((item, index) => {
                                        this.props.myShopApiStore.setMaxSideMenuIdx(index);
                                        return (
                                            <Item item={item} index={index} key={`p_${index}`} toggleBasket={this.props.toggleBasket}/>
                                        )
                                    })} </>

                               
                             :
                            null
                        
                    }
                    {
                        searchValue && !suggestions.length && !isSearchingProducts
                        ?
                            <div className={style.Oops}>
                                <div className={style.title}>אופס...</div>
                                <div className={style.subtitle}>למילת החיפוש שהכנסת לא נמצאו תוצאות</div>
                                <div className={style.subtitle}>נסה להרחיב את תיאור הפריט</div>
                                
                            </div>
                        :
                            null
                    }

                    <div className={loading ? style.LoaderContainer : ''}>
                        <Spinner loader='BeatLoader' loading={loading} color={this.props.myShopApiStore.mainColor}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ItemList;