import React from 'react';
import { observer, inject } from 'mobx-react';
import style from './style.module.css';
import Modal from 'react-modal';
import sad from '../../../../../../assets/sad.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer 
class ScheduleModal extends React.Component {
    constructor(props) {
        super(props);
        this.modalStyle = {
            content : {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%,-50%)',
                padding: 0,
                borderRadius: '20px',
                width: '50%',

            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.75)",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 10,

            },
        }

    } 
    render() {
        const { modalIsOpen, closeModal } = this.props;
        const { mainColor } = this.props.myShopApiStore;
        return (
            <Modal onRequestClose={closeModal}
                   isOpen={modalIsOpen}
                   style={this.modalStyle}   
                   contentLabel={'מוצרים בסל לא זמינים'}>

                <div className={style.ModalContainer}>
                    <div className={style.CloseBtn}>
                        <span onClick={closeModal}>✕</span>
                    </div>
                    <div className={style.Body}>
                        <LazyLoadImage effect='blur' src={sad} width='80px'/>
                        <div className={style.ModalTitle}>
                            <span>ממש מצטערים</span>
                        </div>
                        <div className={style.RowText}>
                            <span>כמה מוצרים בסל לא זמינים עכשיו ונמחקו</span>
                        </div>
                        <button type="text" onClick={closeModal} style={{color: mainColor, border: `1px solid ${mainColor}`}} >
                            עדכון סל
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }
}
   
export default ScheduleModal