import React from "react";
import { observer, inject } from "mobx-react";
import style from "./style.module.css";

@inject((store) => {
  const { myShopApiStore } = store;
  return { myShopApiStore };
})

@observer
class ScheduleCare extends React.Component {
    constructor(props) {
        super(props)
    }
        
    render() {
        
        const { category } = this.props;
        const { mainColor } = this.props.myShopApiStore;
 
        return (
            <div className={style.Container} style={{ backgroundColor: mainColor}} >
                <div className={style.TopDiv} >
                    נא לשים לב שהזמנות מוצרים בקטגוריה הזאת מתקבלות מ- {category.fromTime} עד {category.toTime}
                </div>
            </div>
           
        )
        
    }
}

export default ScheduleCare
